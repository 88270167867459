<template>
  <div id="app">
    <div class="layout">
      <ContentList @select="handleSelect" />
      <DetailTable :selectedItem="selectedItem" />
      <Menu /> 
    </div>
  </div>
</template>

<script>
import ContentList from './ContentList.vue';
import DetailTable from './DetailTable.vue';
import Menu from '../../views/Menu.vue';

export default {
  components: {
    ContentList,
    DetailTable,
    Menu,
  },
  data() {
    return {
      selectedItem: null
    };
  },
  methods: {
    handleSelect(item) {
      this.selectedItem = item;
    }
  }
};
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.layout {
  display: flex;
  width: 100%;
}

.content-list {
  width: 30%;
  padding: 20px;
  border-right: 1px solid #ccc;
}

.detail-table {
  width: 70%;
  padding: 20px;
}
</style>