<template>
  <div>
    <div class="content-list">
      <t-space direction="vertical" style="width: 85%;">
          <h2>映射列表</h2>
          <t-list :split="true">
            <t-list-item v-for="(item, i) in mapList" :key="i">
              <t-list-item-meta :image="imageUrl" :title="item.title" :description="item.description" style="text-align: left" ></t-list-item-meta>
              <t-link theme="primary" hover="color" @click="edit(item)" style="margin-left: 16px">
                <edit-icon /> 修改
              </t-link>
              <t-link theme="primary" hover="color" @click="del(item)" style="margin-left: 16px">
                <edit-icon /> 删除
              </t-link>
            </t-list-item>
          </t-list>
          <t-button theme="primary" @click="addNew">
              <add-icon slot="icon" />新建
          </t-button>
        </t-space>
    </div>
    <div>
      <t-drawer :visible="visible" @close="handleClose" :onConfirm="handleConfirm" header="方案新增">
        <div class="t-drawer-demo-div">
          <t-input label="名称：" v-model="name" clearable :maxlength="10" show-limit-number/>
        </div>
      </t-drawer>
    </div>
  </div>
</template>

<script>
import { Space as TSpace, List as TList, ListItem as TListItem, Link as TLink, ListItemMeta as TListItemMeta,Button as TButton,Input as TInput,Drawer as TDrawer, } from 'tdesign-vue';
import { AddIcon,EditIcon } from 'tdesign-icons-vue';  
import requestAPI from '../../js/ServerAPI.js'
import { EventBus } from '../../js/EventBus.js';

export default {
  components: { TSpace, TList, TListItem, TLink, TListItemMeta, EditIcon ,AddIcon,TButton,TInput,TDrawer},
  data() {
    return {
        imageUrl: 'https://i.52112.com/icon/jpg/256/20200702/82284/3457261.jpg',
        name:'',
        visible: false,
        mapList: [],
      };
  },
  created() {
   this.requestMain();
  },
  methods: {
    edit(item) {
      EventBus.$emit('mapselected', item);
    },
    del(item){
      const postData = {
        enterprise: localStorage.getItem("enterprise"),
        assttype:item.title,
      }
      requestAPI(this.$postUrl+'/asstMapping/delete', postData, (response) => {
        this.requestMain();
      }); 
    },
    addNew(){
      this.visible = true;
    },
    setVisible(state) {
      this.visible = state;
    },
    handleClose() {
      this.setVisible(false);
    },
    handleConfirm(){
      const postData = [{
        enterprise: localStorage.getItem("enterprise"),
        assttype:this.name,
        seq:1,
        nodevalue:'',
        asstvalue:'',
      }]
      requestAPI(this.$postUrl+'/asstMapping/add', postData, (response) => {
        this.requestMain();
      });  
      this.name = '';
      this.visible = false;  
    },
    requestMain(){
      let postData = {enterprise:localStorage.getItem("enterprise")};
      requestAPI(this.$postUrl+'/asstMapping/list/main', postData, (response) => {
        const objects = response.map(str => {
          return JSON.parse(str);
        });

        this.mapList = objects;
      }); 
    }
  }
};
</script>

<style scoped>
.content-list {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, #6dd5ed, #2193b0); /* 添加渐变背景 */
  background-size: cover;
  display: flex;
  justify-content: left;
  align-items: top;
}
</style>