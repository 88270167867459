<template>  
  <div class="t-table-demo__container"> 
    <br>
    <br> 
    <div class="t-table-demo__toolbar">  
      <t-button theme="primary" @click="addRow()">
        <add-icon slot="icon" />新增行
      </t-button> 
      &nbsp;&nbsp;
      <t-button theme="primary" @click="delRow()">
        <add-icon slot="icon" />删除行
      </t-button>  
      &nbsp;&nbsp;
      <t-button theme="primary" @click="save()">
        <add-icon slot="icon" />保存
      </t-button>  
      &nbsp;&nbsp;
      <t-button theme="primary" @click="viewasst()">
        <add-icon slot="icon" />核算映射
      </t-button>  
    </div>  
    <el-card class="t-table-demo__card">  
      <t-table  
        id="ttable"  
        ref="tableRef"  
        row-key="acc"  
        :columns="columns"  
        :data="data"  
        :editable-cell-state="editableCellState"  
        bordered  
        lazyLoad  
      />  
    </el-card> 
    <Menu /> 
  </div>  
</template>  
  
  
<script>  
  import {
    Input, Select, MessagePlugin,Table as TTable,Button as TButton,Switch as TSwitch,
  } from 'tdesign-vue';

  import { AddIcon } from 'tdesign-icons-vue';  
  import requestAPI from '../js/ServerAPI.js'
  import Menu from './Menu.vue';

  export default {
    name: 'TTableEditableCell',
    components: {
      TTable,Input,Select,MessagePlugin,TButton,AddIcon,TSwitch,Menu
    },
    data() {
      return {
        align: 'left',
        data: [],
      };
    },
    created() {
      let postData = {solution:this.$route.params.solution};
      requestAPI(this.$postUrl+'/accMapping/list', postData, (response) => {
          //对digest节点进行类型转换。从string转为array，用来匹配前端展示
          let reversedata = response.map(item => {
            const digestArray = Array.isArray(item.digest) ? item.digest : [item.digest];
            return {
              ...item,
              digest: digestArray
            };
          });
          this.data = reversedata;
      });
    },
    computed: {
      columns() {
        return [
        {
            title: '选择',
            colKey: 'selected',
            width: 60,
            cell: (h, { row }) => {
              return h('el-checkbox', {
                attrs: {
                  value: row.isRowSelected, // 绑定复选框的值到行的选中状态
                },
                on: {
                  change: (value) => {
                    this.$set(row, 'isRowSelected', value); // 更新行的选中状态
                  }
                }
              });
            }
          },
          {
            title: '科目',
            colKey: 'acc',
            align: this.align,
            // 编辑状态相关配置，全部集中在 edit
            edit: {
              // 1. 支持任意组件。需保证组件包含 `value` 和 `onChange` 两个属性，且 onChange 的第一个参数值为 new value。
              // 2. 如果希望支持校验，组件还需包含 `status` 和 `tips` 属性。具体 API 含义参考 Input 组件
              component: Input,
              // props, 透传全部属性到 Input 组件（可以是一个函数，不同行有不同的 props 属性 时，使用 Function）
              props: {
                clearable: true,
                autofocus: true,
              },
              // 除了点击非自身元素退出编辑态之外，还有哪些事件退出编辑态
              abortEditOnEvent: ['onEnter'],
              // 编辑完成，退出编辑态后触发
              onEdited: (context) => {
                this.data.splice(context.rowIndex, 1, context.newRowData);
              },
              // 校验规则，此处同 Form 表单
              rules: [
                { required: true, message: '不能为空' },
                { max: 20, message: '字符数量不能超过 20', type: 'warning' },
              ],
              // 默认是否为编辑状态
              defaultEditable: true,
              // 校验时机：exit | change
              validateTrigger: 'change',
              // 透传给 component: Input 的事件
              on: (editContext) => ({
                onBlur: () => {
                  // console.log('失去焦点', editContext);
                },
                // both onEnter and enter can work
                onEnter: (ctx) => {
                  // console.log('回车', ctx);
                },
              }),
            },
          },
          {
            title: '借方/贷方',
            colKey: 'dc',
            width: 100,
            align: this.align,
            // 使用 cell 属性来定义单元格的渲染方式
            cell: (h, { row, rowIndex }) => {
              if (!row.hasOwnProperty('dc')) {
                row.dc = false; // 设置默认值，或者根据需要设置
              }
              return h(TSwitch, {
                props: {
                  value: row.dc 
                },
                on: {
                  change: (value) => {
                    this.$set(this.data[rowIndex], 'dc', value);
                  }
                }
              });
            }
          },
          {
            title: '核算项目',
            colKey: 'asst',
            align: this.align,
            // 编辑状态相关配置，全部集中在 edit
            edit: {
              // 1. 支持任意组件。需保证组件包含 `value` 和 `onChange` 两个属性，且 onChange 的第一个参数值为 new value。
              // 2. 如果希望支持校验，组件还需包含 `status` 和 `tips` 属性。具体 API 含义参考 Input 组件
              component: Input,
              // props, 透传全部属性到 Input 组件（可以是一个函数，不同行有不同的 props 属性 时，使用 Function）
              props: {
                clearable: true,
                autofocus: true,
              },
              // 除了点击非自身元素退出编辑态之外，还有哪些事件退出编辑态
              abortEditOnEvent: ['onEnter'],
              // 编辑完成，退出编辑态后触发
              onEdited: (context) => {
                this.data.splice(context.rowIndex, 1, context.newRowData);
              },
              // 校验规则，此处同 Form 表单
              rules: [
                { max: 100, message: '字符数量不能超过 100', type: 'warning' },
              ],
              // 默认是否为编辑状态
              defaultEditable: true,
              // 校验时机：exit | change
              validateTrigger: 'change',
              // 透传给 component: Input 的事件
              on: (editContext) => ({
                onBlur: () => {
                  // console.log('失去焦点', editContext);
                },
                // both onEnter and enter can work
                onEnter: (ctx) => {
                  // console.log('回车', ctx);
                },
              }),
            },
          },
          {
            title: '金额',
            colKey: 'amount',
            width: 100,
            edit: {
              component: Select,
              // props, 透传全部属性到 Select 组件
              props: {
                clearable: true,
                options: this.$route.params.amtlist,
              },
              // 除了点击非自身元素退出编辑态之外，还有哪些事件退出编辑态
              // abortEditOnEvent: ['onChange'],
              // 编辑完成，退出编辑态后触发
              onEdited: (context) => {
                this.data.splice(context.rowIndex, 1, context.newRowData);
              },
            }
          },
          {
            title: '表达式',
            colKey: 'lambda',
            align: this.align,
            // 编辑状态相关配置，全部集中在 edit
            edit: {
              // 1. 支持任意组件。需保证组件包含 `value` 和 `onChange` 两个属性，且 onChange 的第一个参数值为 new value。
              // 2. 如果希望支持校验，组件还需包含 `status` 和 `tips` 属性。具体 API 含义参考 Input 组件
              component: Input,
              // props, 透传全部属性到 Input 组件（可以是一个函数，不同行有不同的 props 属性 时，使用 Function）
              props: {
                clearable: true,
                autofocus: true,
              },
              // 除了点击非自身元素退出编辑态之外，还有哪些事件退出编辑态
              abortEditOnEvent: ['onEnter'],
              // 编辑完成，退出编辑态后触发
              onEdited: (context) => {
                this.data.splice(context.rowIndex, 1, context.newRowData);
              },
              // 校验规则，此处同 Form 表单
              rules: [
                { max: 300, message: '字符数量不能超过 300', type: 'warning' },
              ],
              // 默认是否为编辑状态
              defaultEditable: true,
              // 校验时机：exit | change
              validateTrigger: 'change',
              // 透传给 component: Input 的事件
              on: (editContext) => ({
                onBlur: () => {
                  // console.log('失去焦点', editContext);
                },
                // both onEnter and enter can work
                onEnter: (ctx) => {
                  // console.log('回车', ctx);
                },
              }),
            },
          },
          {
            title: '摘要',
            colKey: 'digest',
            cell: (h, { row }) => row.letters.join('、'),
            edit: {
              component: Select,
              keepEditMode: true,
              // props, 透传全部属性到 Select 组件
              // props 为函数时，参数有：col, row, rowIndex, colIndex, editedRow。一般用于实现编辑组件之间的联动
              props: ({
                col, row, rowIndex, colIndex, editedRow,
              }) => {
                return {
                  multiple: true,
                  minCollapsedNum: 1,
                  options: this.$route.params.digestlist,
                };
              },
              // abortEditOnEvent: ['onChange'],
              onEdited: (context) => {
                this.data.splice(context.rowIndex, 1, context.newRowData);
              },
            },
          },
          {
            title: '合并',
            colKey: 'merge',
            width: 70,
            align: this.align,
            // 使用 cell 属性来定义单元格的渲染方式
            cell: (h, { row, rowIndex }) => {
              if (!row.hasOwnProperty('merge')) {
                row.merge = false; // 设置默认值，或者根据需要设置
              }
              return h(TSwitch, {
                props: {
                  value: row.merge 
                },
                on: {
                  change: (value) => {
                    this.$set(this.data[rowIndex], 'merge', value);
                  }
                }
              });
            }
          },
          {
            title: '扣减',
            colKey: 'deduct',
            width: 70,
            align: this.align,
            // 使用 cell 属性来定义单元格的渲染方式
            cell: (h, { row, rowIndex }) => {
              if (!row.hasOwnProperty('deduct')) {
                row.deduct = false; // 设置默认值，或者根据需要设置
              }
              return h(TSwitch, {
                props: {
                  value: row.deduct 
                },
                on: {
                  change: (value) => {
                    this.$set(this.data[rowIndex], 'deduct', value);
                  }
                }
              });
            }
          },
          {
            title: '跨合',
            colKey: 'spanmerge',
            width: 70,
            align: this.align,
            // 使用 cell 属性来定义单元格的渲染方式
            cell: (h, { row, rowIndex }) => {
              if (!row.hasOwnProperty('spanmerge')) {
                row.spanmerge = false; // 设置默认值，或者根据需要设置
              }
              return h(TSwitch, {
                props: {
                  value: row.spanmerge 
                },
                on: {
                  change: (value) => {
                    this.$set(this.data[rowIndex], 'spanmerge', value);
                  }
                }
              });
            }
          }
        ];
      },
    },

    methods: {
      // 用于控制哪些行或哪些单元格不允许出现编辑态
      editableCellState(cellParams) {
        const { rowIndex } = cellParams;
        return rowIndex !== -1;
      },

      addRow(){
        const newRow = {
          solution:this.$route.params.solution,
          acc: '', 
          asst:'',
          dc:true,
          amount: '', 
          lambda:'',
          digest:[],
          merge:false,
        };
        // 将新行添加到数据源中
        this.data.push(newRow);
      },
      delRow(){
        // 首先，找到所有选中行的索引
        const selectedIndices = this.data.reduce((indices, row, index) => {
          if (row.isRowSelected) {
            indices.push(index);
          }
          return indices;
        }, []);
        
        // 然后，根据索引从数据中删除选中的行
        selectedIndices.reverse().forEach(index => {
          this.data.splice(index, 1);
        });
      },
      save(){

        //自动增加行号，同时将digest由数组转换为字符串方便保存
        const updatedData = this.data.map((item, index) => {
          // 确保digest属性是数组，然后将其转换为字符串
          const digestStr = Array.isArray(item.digest) ? item.digest.join(';') : item.digest;
          // 创建新对象，添加seq属性，同时处理digest属性
          const newItem = {
            ...item,
            seq: index + 1, // 添加行号属性
            digest: digestStr // 转换digest属性
          };
          return newItem;
        });

        requestAPI(this.$postUrl+'/accMapping/add', updatedData, (response) => {
          MessagePlugin.success("保存成功");
        });
      },
      viewasst(){
        this.$router.replace({
          name: 'AsstMapping',
          query: {
            solutionID: this.$route.params.solutionID,
          }
        });
      }
      // 用于提交前校验数据（示例代码有效，勿删）
      // validateTableData() {
      //   // 仅校验处于编辑态的单元格
      //   this.$refs.tableRef.validateTableData().then((result) => {
      //     console.log('validate result: ', result);
      //   });
      // },
    },
  }; 
</script>


<style scoped>  
.t-table-demo__container {  
  display: flex;  
  flex-direction: column;  
  align-items: center;  
  justify-content: top;  
  height: 750px;  
  background: linear-gradient(to right, #6dd5ed, #2193b0); /* 添加渐变背景 */
}  
  
.t-table-demo__toolbar {  
  display: flex;  
  justify-content: flex-end;  
  margin-bottom: 16px;  
}  
  
.t-table-demo__card {  
  width: 85%;  
  margin-bottom: 24px;  
}  
  
</style>  