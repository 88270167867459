<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  methods: {
    goToHome() {
      this.$router.push({ name: 'Login' });
    }
  }
};
</script>