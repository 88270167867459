<template>
    <div class="login-container">
      <el-card class="login-card">
        <h2 class="login-title">登录</h2>
        <el-form :model="loginForm" :rules="loginRules" ref="loginFormRef">
          <el-form-item label="用户名" prop="username">
            <el-input ref="userInput" v-model="loginForm.username" auto-complete="on" @change="clear"  @keyup.enter.native="focusPassword" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input ref="passwordInput" type="password" v-model="loginForm.password" @keyup.enter.native="handleSubmit"  placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item label="企业"  v-show="isVisible">
            <select id="enterprise" v-model="selectedValue">
                <option v-for="item in options" :key="item.enterprise" :value="item.enterprise">
                    {{ item.note }}
                </option>
            </select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSubmit">登录</el-button>
          </el-form-item>
        </el-form>
      </el-card>
      <router-view></router-view>
    </div>
  </template>
  
  <script>
  import { Card, Form, Input, Button,FormItem} from 'element-ui';
  import { MessagePlugin, } from 'tdesign-vue';
  import requestAPI from '../js/ServerAPI.js'
    
    export default {
      components: {
        [Card.name]: Card,
        [Form.name]: Form,
        [Input.name]: Input,
        [Button.name]: Button,
        [FormItem.name]:FormItem,
      },
      data() {
        return {
          loginForm: {
            username: localStorage.getItem("registername"),
            password: '',
          },
          loginRules: {
            username: [
              { required: true, message: '请输入用户名', trigger: 'blur' },
              { min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur' }
            ],
            password: [
              { required: true, message: '请输入密码', trigger: 'blur' },
              { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
            ],
            enterprise: [
              { required: true, message: '请选择企业', trigger: 'blur' },
            ]
          },
          options: [],
            // 初始化选中的值，可以设置为options中的任意一个value
          selectedValue: '', // 默认值,
          isVisible:false
        };
      },
      created() {
        const register = localStorage.getItem("registername");
        const loginList = localStorage.getItem("loginList");
        if(loginList != null && loginList.length > 0){
            const hashMap = new Map(JSON.parse(loginList));
            
            this.options = JSON.parse(hashMap.get(register+'Option')); 
            this.selectedValue = JSON.parse(hashMap.get(register+'Selected'));
            this.isVisible = true;
        }
     },
      methods: {
        focusUser(){
          this.$refs.userInput.focus();
        },
        focusPassword() {  
          this.$refs.passwordInput.focus();
        },
        clear(){
            this.options = [];
            this.selectedValue = '';
        },
        async handleSubmit() {            
            const postData = {
                username: this.loginForm.username,
                password: this.loginForm.password
            }
             // 保存对 Vue 实例的引用
            const self = this;

            await requestAPI(this.$postUrl+'/register/login',postData, function(response){
              
                if(response == null || response.size == 0) {
                    MessagePlugin.error('登录失败');
                    return false;
                } 

                self.options = response;
                self.isVisible = true;

                //首次登录时
                if(self.selectedValue == null || self.selectedValue == ''){
                    MessagePlugin.warning('请选择企业');
                    self.isVisible = true;   
                    return false;             
                }
                //页面进行跳转
                const hashMap = new Map();
                // put操作
                hashMap.set(self.loginForm.username+'Option',JSON.stringify(self.options));
                hashMap.set(self.loginForm.username+'Selected',self.selectedValue);

                localStorage.setItem('registername', self.loginForm.username);

                localStorage.setItem('loginList', JSON.stringify(Array.from(hashMap)));

                self.$router.push(
                {
                    name: 'FileUploadPC',
                    params: {
                      enterprise: self.selectedValue
                    }
                });
            });
        }
      }
    };
  </script>
  
  <style>
  .login-container {
    display: flex;
    flex-direction: column;  
    align-items: center;  
    justify-content: center;  
    height: 100vh;  
    background: linear-gradient(to right, #6dd5ed, #2193b0); /* 添加渐变背景 */
  }
  
  .login-card {
    width: 400px;
    border-radius: 10px; /* 圆角边框 */
    background: #fff; /* 白色背景 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 添加阴影 */
    padding: 30px; /* 内部填充 */
  }
  
  .login-title {
    text-align: center;
    color: #333; /* 字体颜色 */
    margin-bottom: 20px; /* 标题与表单间距 */
  }
  
  /* 调整表单元素样式 */
  .el-form-item__label {
    color: #666; /* 标签字体颜色 */
  }
  
  .el-input__inner {
    border-radius: 4px; /* 输入框圆角 */
  }
  
  .el-button {
    width: 100%; /* 按钮宽度 */
    border-radius: 20px; /* 按钮圆角 */
    font-weight: bold; /* 字体加粗 */
    letter-spacing: 2px; /* 字间距 */
  }
  
  /* 调整select元素的样式以匹配el-input的样式 */
  .login-container select {
    width: 100%; /* 占满父元素宽度 */
    padding: 0 15px; /* 内边距，与el-input的内边距保持一致 */
    border: 1px solid #dcdfe6; /* 边框样式，与el-input的边框保持一致 */
    border-radius: 4px; /* 边框圆角，与el-input保持一致 */
    height: 40px; /* 选择框的高度，根据el-input的高度调整 */
    line-height: 40px; /* 行高，与高度保持一致，以确保文本垂直居中 */
    color: #606266; /* 字体颜色，与el-input保持一致 */
    background-color: #fff; /* 背景颜色，与el-input保持一致 */
    -webkit-appearance: none; /* 移除webkit浏览器的默认样式 */
    -moz-appearance: none; /* 移除firefox浏览器的默认样式 */
    appearance: none; /* 移除其他浏览器的默认样式 */
  }

  /* 可选：添加悬停效果 */
  .login-container select:hover {
    border-color: #409eff; /* 悬停时的边框颜色，与el-input的悬停效果保持一致 */
  }

  /* 可选：添加聚焦效果 */
  .login-container select:focus {
    outline: none; /* 移除默认的聚焦轮廓 */
    border-color: #409eff; /* 聚焦时的边框颜色 */
    box-shadow: 0 0 0 2px rgba(64, 158, 255, 0.2); /* 添加聚焦时的阴影效果 */
  }

  /* 响应式设计 */
  @media (max-width: 600px) {
    .login-card {
      width: 90%;
    }
  }
  </style>