import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

Vue.config.productionTip = false
Vue.prototype.$axios = axios;
// Vue.prototype.$postUrl = 'https://127.0.0.1:443';
Vue.prototype.$postUrl = 'https://www.xianyunyatech.com';
Vue.prototype.$apiKey = 'sk-7B6P2b56zqKg8sZ3xhHjvzpvH9vR7tGteaQLY9RpqGrP08H2'

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
