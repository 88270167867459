<template>  
  <div class="t-table-demo__container"> 
    <br>
    <br> 
    <div class="t-table-demo__toolbar">  
      <t-button theme="primary" @click="addRow()">
        <add-icon slot="icon" />新增行
      </t-button> 
      &nbsp;&nbsp;
      <t-button theme="primary" @click="delRow()">
        <add-icon slot="icon" />删除行
      </t-button>  
      &nbsp;&nbsp;
      <t-button theme="primary" @click="save()">
        <add-icon slot="icon" />保存
      </t-button>  
    </div>  
    <el-card class="t-table-demo__card">  
      <label ref="lbTitle">请左侧选择</label>
      <br><br>
      <t-table  
        id="ttable"  
        ref="tableRef"  
        row-key="nodevalue"  
        :columns="columns"  
        :data="data"  
        :editable-cell-state="editableCellState"  
        bordered  
        lazyLoad  
      />  
    </el-card>  
  </div>  
</template>  
  
<script>  
  import {
    Table as TTable,Input, MessagePlugin,Button as TButton,Tag as TTag,
  } from 'tdesign-vue';
  import { AddIcon } from 'tdesign-icons-vue';  
  import {EventBus} from '../../js/EventBus.js'
  import requestAPI from '../../js/ServerAPI.js'

  export default {
    name: 'TTableEditableCell',
    components: {
      TTable,Input,MessagePlugin,TButton,AddIcon,TTag
    },
    data() {
      return {
        align: 'left',
        //记录一下类型
        assttype:'',
        data: [],
      };
    },
    mounted() {
      EventBus.$on('mapselected', this.handleEvent);
    },
    computed: {
      columns() {
        return [
          {
            title: '选择',
            colKey: 'selected',
            width: 60,
            cell: (h, { row }) => {
              return h('el-checkbox', {
                attrs: {
                  value: row.isRowSelected, // 绑定复选框的值到行的选中状态
                },
                on: {
                  change: (value) => {
                    this.$set(row, 'isRowSelected', value); // 更新行的选中状态
                  }
                }
              });
            }
          },
          {
            title: '节点值',
            colKey: 'nodevalue',
            align: this.align,
            // 编辑状态相关配置，全部集中在 edit
            edit: {
              // 1. 支持任意组件。需保证组件包含 `value` 和 `onChange` 两个属性，且 onChange 的第一个参数值为 new value。
              // 2. 如果希望支持校验，组件还需包含 `status` 和 `tips` 属性。具体 API 含义参考 Input 组件
              component: Input,
              // props, 透传全部属性到 Input 组件（可以是一个函数，不同行有不同的 props 属性 时，使用 Function）
              props: {
                clearable: true,
                autofocus: true,
              },
              // 除了点击非自身元素退出编辑态之外，还有哪些事件退出编辑态
              abortEditOnEvent: ['onEnter'],
              // 编辑完成，退出编辑态后触发
              onEdited: (context) => {
                this.data.splice(context.rowIndex, 1, context.newRowData);
              },
              // 校验规则，此处同 Form 表单
              rules: [
                { max: 300, message: '字符数量不能超过 300', type: 'warning' },
              ],
              // 默认是否为编辑状态
              defaultEditable: true,
              // 校验时机：exit | change
              validateTrigger: 'change',
              // 透传给 component: Input 的事件
              on: (editContext) => ({
                onBlur: () => {
                  // console.log('失去焦点', editContext);
                },
                // both onEnter and enter can work
                onEnter: (ctx) => {
                  // console.log('回车', ctx);
                },
              }),
            },
          },
          {
            title: '映射值',
            colKey: 'asstvalue',
            align: this.align,
            // 编辑状态相关配置，全部集中在 edit
            edit: {
              // 1. 支持任意组件。需保证组件包含 `value` 和 `onChange` 两个属性，且 onChange 的第一个参数值为 new value。
              // 2. 如果希望支持校验，组件还需包含 `status` 和 `tips` 属性。具体 API 含义参考 Input 组件
              component: Input,
              // props, 透传全部属性到 Input 组件（可以是一个函数，不同行有不同的 props 属性 时，使用 Function）
              props: {
                clearable: true,
                autofocus: true,
              },
              // 除了点击非自身元素退出编辑态之外，还有哪些事件退出编辑态
              abortEditOnEvent: ['onEnter'],
              // 编辑完成，退出编辑态后触发
              onEdited: (context) => {
                this.data.splice(context.rowIndex, 1, context.newRowData);
              },
              // 校验规则，此处同 Form 表单
              rules: [
                { max: 300, message: '字符数量不能超过 300', type: 'warning' },
              ],
              // 默认是否为编辑状态
              defaultEditable: true,
              // 校验时机：exit | change
              validateTrigger: 'change',
              // 透传给 component: Input 的事件
              on: (editContext) => ({
                onBlur: () => {
                  // console.log('失去焦点', editContext);
                },
                // both onEnter and enter can work
                onEnter: (ctx) => {
                  // console.log('回车', ctx);
                },
              }),
            },
          },
        ]
      },
    },
    methods: {
      // 用于控制哪些行或哪些单元格不允许出现编辑态
      editableCellState(cellParams) {
        const { rowIndex } = cellParams;
        return rowIndex !== -1;
      },
      addRow(){
        const newRow = {
          nodevalue: '', 
          asstvalue: '', 
        };
        // 将新行添加到数据源中
        this.data.push(newRow);
      },
      delRow(){
         // 首先，找到所有选中行的索引
         const selectedIndices = this.data.reduce((indices, row, index) => {
          if (row.isRowSelected) {
            indices.push(index);
          }
          return indices;
        }, []);
        
        // 然后，根据索引从数据中删除选中的行
        selectedIndices.reverse().forEach(index => {
          this.data.splice(index, 1);
        });
      },
      save(){
        const updatedData = this.data.map((item, index) => {
          // 创建新对象，添加seq属性
          const newItem = {
            ...item,
            seq: index + 1, // 添加行号属性
            enterprise:localStorage.getItem("enterprise"),  //企业ID
            assttype: this.assttype,//类型
          };
          return newItem;
        });
        requestAPI(this.$postUrl+'/asstMapping/add', updatedData, (response) => {
        });
        MessagePlugin.success("保存成功!");
      },
      handleEvent(data) {
        this.assttype = data.title;
        this.$refs.lbTitle.textContent = data.title+'映射详情';
        let postData = {
          enterprise:localStorage.getItem("enterprise"),
          assttype:data.title,
        };
        requestAPI(this.$postUrl+'/asstMapping/list/detail', postData, (response) => {
            this.data = response;
        });
      }
      // 用于提交前校验数据（示例代码有效，勿删）
      // validateTableData() {
      //   // 仅校验处于编辑态的单元格
      //   this.$refs.tableRef.validateTableData().then((result) => {
      //     console.log('validate result: ', result);
      //   });
      // },
    },
    beforeDestroy() {
      EventBus.$off('mapselected', this.handleEvent);
    }
  }; 
</script>


<style scoped>  
.t-table-demo__container {  
  display: flex;  
  flex-direction: column;  
  align-items: center;  
  position: fixed;
  right: 0;
  top:0;
  background-size: cover;
  justify-content: center;
  align-items: top;
  width:65%
}  
  
.t-table-demo__toolbar {  
  display: flex;  
  justify-content: flex-end;  
  margin-bottom: 16px;  
}  
  
.t-table-demo__card {  
  width: 85%;  
  margin-bottom: 24px;  
}  

.detail-table {
  position: fixed;
  right: 0;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: top;
  width:65%
}
</style>  