import { render, staticRenderFns } from "./FileUploadPC.vue?vue&type=template&id=2be1ffad&scoped=true"
import script from "./FileUploadPC.vue?vue&type=script&lang=js"
export * from "./FileUploadPC.vue?vue&type=script&lang=js"
import style0 from "./FileUploadPC.vue?vue&type=style&index=0&id=2be1ffad&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2be1ffad",
  null
  
)

export default component.exports