<template>
  <div class="app-container">
    <div class="header">
      <h2>方案数据源选择</h2>
    </div>
    <div class="container">
      <div class="left-content">
        <t-space direction="vertical">
          <t-tree
            :data="items"
            hover
            :expand-mutex=true
            :expand-on-click-node=false
            :transition=true
            :line=true
            @click="onNodeSelected"
          />
        </t-space>
      </div>
      <div class="vertical-separator"></div>
      <div class="right-content">
        <el-table :data="tableData" width="800px">
          <el-table-column prop="node" label="节点" width="120px" ></el-table-column>
          <el-table-column label="备注" width="120px" >
            <template slot-scope="scope" >
              <t-tagInput v-model="scope.row.note" autoWidth clearable :tag-props="{ theme: 'success' }" />  
            </template>
          </el-table-column>
          <el-table-column prop="purpose" label="用途">  
            <template slot-scope="scope" >
              <t-radio-group variant="primary-filled" v-model="scope.row.purpose">
                <t-radio-button value="1">金额</t-radio-button>
                <t-radio-button value="2">摘要</t-radio-button>
                <t-radio-button value="3">核算项目</t-radio-button>
              </t-radio-group>
            </template>
          </el-table-column> 
          <!-- 操作列 -->  
          <el-table-column label="操作" width="80px">  
            <template slot-scope="scope">  
              <el-button @click="handleDelete(scope.$index, scope.row)">删除</el-button>  
            </template>  
          </el-table-column> 
        </el-table>
        <br>
        <div style="text-align: center;">
          <t-button theme="primary" @click="nextPage">
            <add-icon slot="icon" />下一步
          </t-button>        
        </div>
      </div>
    </div>
    <Menu />
  </div>
</template>

<script>
  import ElementUI from 'element-ui'
  import 'element-ui/lib/theme-chalk/index.css'
  import jsonToTree from '../js/InitTree.js'
  import requestAPI from '../js/ServerAPI.js'
  import { TagInput as TTagInput,Table as TTable,RadioGroup as TRadioGroup,
    RadioButton as TRadioButton,MessagePlugin ,Space as TSpace,Tree as TTree ,Button as TButton} from 'tdesign-vue'
  import { AddIcon } from 'tdesign-icons-vue';  

  // 引入组件库的少量全局样式变量
  import 'tdesign-vue/es/style/index.css';
  import Menu from './Menu.vue';

  export default {
    components: {
      TTagInput, TTable,ElementUI,TRadioGroup,TRadioButton,ElementUI,TTree,TSpace,TButton,AddIcon,Menu,
    },
    data() {
      return {
        tableData: [],
        items: []
      }
    },
    async created() {
      this.items = jsonToTree(JSON.parse(this.$route.params.result));      
      let postData = {solution:this.$route.params.solution};
      requestAPI(this.$postUrl+'/tagSource/list', postData, (response) => {
          //对note节点进行类型转换。从string转为array，用来匹配前端展示
          let data = response.map(item => {
            const noteArray = Array.isArray(item.note) ? item.note : [item.note];
            return {
              ...item,
              note: noteArray
            };
          });
          this.tableData = data;
      });
    },
    methods: {
      handleDelete(index, row) {
        this.tableData.splice(index, 1);
      },
      onNodeSelected(context) {
        const { node } = context;
        if(node.isLeaf()){
          const newrow = {
            solution: this.$route.params.solution,
            seq: this.tableData.length+1,
            node: node.label, 
            note: [],
            purpose: ''
          }
          this.tableData.push(newrow);
        };

      },
      nextPage(){
        
        let sourceAmtArray = this.tableData.filter(item => item.purpose === "1");
        if(sourceAmtArray.length == 0 ){
          MessagePlugin.warning("请选择金额来源")
          return false;
        }

        let targetAmtArray = [];
        sourceAmtArray.forEach(element => {
          targetAmtArray.push(
            {
              label: element.node,
              value: element.node
            }
          );
        });

        let sourceDigestArray = this.tableData.filter(item => item.purpose === "2");
        if(sourceDigestArray.length == 0 ){
          MessagePlugin.warning("请选择摘要来源")
          return false;
        }

        let targetDigestArray = [];
        sourceDigestArray.forEach(element => {
          targetDigestArray.push(
            {
              label: element.node,
              value: element.node
            }
          );
        });

        let sourceAssetArray = this.tableData.filter(item => item.purpose === "3");
        let targetAssetArray = [];
        sourceAssetArray.forEach(element => {
          targetAssetArray.push(
            {
              label: element.node,
              value: element.node
            }
          );
        });

        //这段是将note的数组转换成一个string内容
        const updatedData = this.tableData.map(item => {
          return {
            ...item, // 展开剩余的属性
            // 将note数组转换为以逗号分隔的字符串
            note: item.note.join(',')
          };
        });

        requestAPI(this.$postUrl+'/tagSource/addBatch', updatedData, (response) => {
            this.$router.push({
              name: 'VchTemplate',
              params: {
                solution: this.$route.params.solution,
                amtlist: targetAmtArray,
                digestlist: targetDigestArray,
                assetList:targetAssetArray
              }
            });
        });
      }
    }
  }
</script>

<style>
.app-container {
  font-family: 'Avenir', Helvetica, Arial, sans-serif; /* 优化字体 */
  background: linear-gradient(to right, #6dd5ed, #2193b0); /* 添加渐变背景 */
  padding: 20px;
  height: 100vh;
}

.header h2 {
  color: #333; /* 标题颜色 */
  text-align: center;
  margin-bottom: 20px;
}

.container {
  display: flex;
}

.left-content, .right-content {
  padding: 20px;
  background: #fff; /* 卡片背景颜色 */
  border-radius: 8px; /* 卡片圆角 */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* 卡片阴影 */
}

.left-content {
  flex: 0 0 40%;
}

.right-content {
  flex: 1;
  margin-left: 20px; /* 右边内容与左边内容间距 */
}

.vertical-separator {
  border-left: 1px solid #ccc;
  height: 100%;
  margin: 0 20px;
}

.table-card, .tree-card {
  overflow: auto; /* 如果内容溢出，启用滚动 */
}

.action-buttons {
  margin-top: 20px;
}

/* 其他样式根据需要添加 */
</style>