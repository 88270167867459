<template>
  <div class = "navigation" width="200px">
    <t-menu theme="light" defaultValue="2-1" :collapsed="collapsed" @change="changeHandler">
      <template #logo>
        <span>导航</span>
      </template>
      <t-menu-item value="solution" >
        <template #icon>
          <icon name="data" />
        </template>
        方案
      </t-menu-item>
      <t-menu-item value="asset" >
        <template #icon>
          <icon name="setting-1" />
        </template>
        配置
      </t-menu-item>
      <t-menu-item value="login">
        <template #icon>
          <icon name="logout" />
        </template>
        退出
      </t-menu-item>
      <template #operations>
        <t-button class="t-demo-collapse-btn" variant="text" shape="square" @click.native="changeCollapsed">
          <icon :name="iconName" slot="icon" />
        </t-button>
      </template>
    </t-menu>
  </div>
  </template>
  
  <script>
  import { Icon } from 'tdesign-icons-vue';
  import { Menu as TMenu ,MenuItem as TMenuItem,Button as TButton} from 'tdesign-vue';
  
  export default {
    components: {
      Icon,TMenu,TMenuItem,TButton
    },
    data() {
      return {
        collapsed: true,
      };
    },
    computed: {
      iconName() {
        return this.collapsed ? 'chevron-right' : 'chevron-left';
      },
    },
    methods: {
      changeCollapsed() {
        this.collapsed = !this.collapsed;
      },
      changeHandler(active){
        let pushurl;
        let urlparams = {};
        switch(active){
          case "login":
            pushurl = "Login";
            break;
          case "solution":
            pushurl = "SolutionList";
            urlparams.enterprise = localStorage.getItem('enterprise');
            break;
          case "asset":
            pushurl = "AsstMapping";
            break;
        }

        this.$router.push(
        {
          name: pushurl,
          params: urlparams
        }); 

      }
    },
  };
  </script>

<style scoped>
.navigation {
  position: fixed; /* 固定定位，相对于视口 */
  top: 50%; /* 定位到视口的垂直中心 */
  right: 0; /* 定位到视口的最左边 */
  transform: translateY(-50%); /* 使用transform属性将元素向上移动自身高度的50%，实现居中 */
  width: 100px; /* 设置导航组件的宽度 */
  height: 260px; /* 设置导航组件的高度为视口高度 */
  background: transparent; /* 半透明背景 */
  z-index: 1001; /* 确保这个导航组件在父组件之上 */
}
</style>