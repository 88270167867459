function jsonToTree(json) {
    // 如果是数组，只取第一个元素
    if (Array.isArray(json)) {
        return jsonToTree(json[0]);
    }

    // 如果是对象，遍历其属性
    if (typeof json === 'object') {
        // 过滤掉函数类型的属性
        const entries = Object.entries(json).filter(([key, value]) => typeof value !== 'function');

        // 构建树节点
        const treeNode = entries.map(([key, value]) => {
        // 如果属性值是数组，则只取数组的第一个元素
        if (Array.isArray(value)) {
            value = value[0];
        }
        // 如果属性值是对象，则递归构建子树
        const children = (typeof value === 'object') ? jsonToTree(value) : null;

        return {
            label: key, // 节点显示的标签为属性名
            children: children // 子节点
        };
        });

        // 返回树节点数组
        return treeNode;
    }

    // 基本数据类型直接返回null
    return null;
  }

export default jsonToTree