import axios from 'axios';
import { MessagePlugin } from 'tdesign-vue';

function requestAPI(url, data, callback){

    const header = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    axios.post(url, data, header)
    .then(response => {
        if(response.status == 200){
            callback(response.data);
        }
        else{
            MessagePlugin.error('内容渲染失败!');
            console.log('error:url---'+url+'\n data---'+JSON.stringify(data));
            return false;
        }
    })
    .catch(error => {
        MessagePlugin.error('数据请求失败!');
        console.log(error)
    }) 
}

export default requestAPI