<template>
  <div class="container">
    <div>
      <h2>文件上传</h2>
      <input
        id="fileInput"
        type="file"
        multiple
        @change="handleFileUpload"
        accept="*"
        placeholder="点击选择文件"
      />
      <div style="text-align: center;">
        <t-button  @click="analyzeContent" v-show="analyVisiable">生成</t-button>    
      </div>
      <ul class="file-list">
        <li v-for="(file, index) in selectedFiles" :key="index">
          {{ file.name }}
          <button @click="removeFile(index)" class="remove-btn">&times;</button>
        </li>
      </ul>
    </div>
    <div>
      正在生成
      <progress :value="progress" max="100"></progress>
      <span>{{ progress }}%</span>
    </div>
  </div>
</template>

<script>

import Vue from 'vue';
import { MessagePlugin ,Button as TButton} from 'tdesign-vue';
import WebSocketService from '../js/WebSocketService.js';

Vue.use(WebSocketService);

export default {  
  components: { MessagePlugin,TButton},
  data() {  
    return {  
      selectedFiles: [],
      that: this,
      analyVisiable: true,
      progress:0,
    };  
  },  
  mounted() {
    this.$on('progress-updated', (data) => {
      this.progress = data.percentage;
    });
  },
  // 如果组件被销毁，注销事件监听器
  beforeDestroy() {
    this.$off('progress-updated');
  },
  methods: {  
    handleFileUpload(event) {  
      // 获取用户选择的文件  
      const files = event.target.files;  
      for (let i = 0; i < files.length; i++) {  
        this.selectedFiles.push(files[i]);  
      }  
      this.progress = 0;
    },  
    async analyzeContent() {
      this.analyVisiable = false;
      this.progress = 0;
      const intervalId = setInterval(this.addprocess, 100);

      // 创建 FormData 对象
      const formData = new FormData();

      // 遍历文件数组，并将每个文件添加到 FormData 对象中
      this.selectedFiles.forEach(file => {
        formData.append('files', file); // 'files' 是后端接收文件的参数名
      });

      // 添加普通参数
      formData.append('enterprise', this.$route.params.enterprise);

      // 设置 axios 请求配置
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data', // 必须设置，否则 axios 会默认设置为 application/json
        },
      };

      // 发送 POST 请求
      this.$axios.post(this.$postUrl+'/user/analyze', formData, config)
        .then(response => {
          // 请求成功，处理响应数据
          console.log(response);
          if(response.data == "success"){
            MessagePlugin.success('处理成功');
            this.progress = 100;
          }else{
            MessagePlugin.error('失败，请查看'+response.data);
          }
          this.analyVisiable = true;
          clearInterval(intervalId);
        })
        .catch(error => {
          // 请求失败，处理错误
          console.error(error);
          MessagePlugin.error('失败，请查看'+response.data);
          this.analyVisiable = true;
          this.progress = 100;
          clearInterval(intervalId);
        });
    },
    removeFile(index) {
      this.selectedFiles.splice(index, 1); // 从数组中移除文件
    },
    addprocess() {
      if(this.progress >= 100)
        this.progress = 100;
      else
        this.progress = this.progress + 1;
    }
  }  
};  
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  height: 100vh;
  background: linear-gradient(to right, #6dd5ed, #2193b0); /* 渐变背景 */
  padding: 20px; /* 添加内边距 */
}

input[type="file"] {
  display: block;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc; /* 添加边框 */
  border-radius: 5px; /* 圆角边框 */
  background-color: white; /* 白色背景 */
  color: #333; /* 字体颜色 */
}

button {
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: #2c3e50; /* 按钮背景色 */
  color: white;
  font-weight: bold;
  cursor: pointer;
}

button:hover {
  background-color: #34495e;
}

.file-list {
  list-style-type: none; /* 移除列表项默认的标记 */
  padding: 0;
  margin-top: 10px;
  width: 100%;
  max-width: 400px; /* 最大宽度 */
  border: 1px solid #ccc; /* 边框 */
  background-color: #f9f9f9; /* 背景色 */
}

.file-list li {
  padding: 8px 15px;
  border-bottom: 1px solid #eee; /* 列表项分隔线 */
  color: #333; /* 字体颜色 */
}

.file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px;
  border-bottom: 1px solid #eee;
}

.remove-btn {
  background: none;
  border: none;
  color: red;
  font-size: 16px;
  cursor: pointer;
}

.remove-btn:focus {
  outline: none;
}

/* 响应式设计 */
@media (max-width: 600px) {
  .login-container {
    padding: 10px;
  }

  .file-list {
    width: 95%;
  }
}
</style>