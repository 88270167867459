import SockJS from 'sockjs-client';
import Stomp from 'stompjs';

export default {
  install(Vue) {
    // const socket = new SockJS('http://127.0.0.1:9090/yunya/generate-progress');
    // const stompClient = Stomp.over(socket);

    // stompClient.connect({}, frame => {
    //   console.log('Connected: ' + frame);
    //   stompClient.subscribe('/topic/queue', progress => {
    //     const progressData = JSON.parse(progress.body);
    //     Vue.$emit('progress-updated', progressData);
    //   });
    // });

    // Vue.prototype.$stompClient = stompClient;
  }
};