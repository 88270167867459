<template>
  <div>
    <div class="full-screen-background">
      <t-space direction="vertical" style="width: 85%;">
        <h2>已创建方案列表</h2>
        <t-list :split="true">
          <t-list-item v-for="(item, i) in solutionList" :key="i">
            <t-list-item-meta :image="imageUrl" :id="item.id" :title="item.name" :description="item.description"></t-list-item-meta>
            <t-link theme="primary" hover="color" @click="edit(item.id,item.name,item.description)" style="margin-left: 16px">
              <edit-icon /> 修改
            </t-link>
            <t-link theme="primary" hover="color" @click="remove(item.id)" style="margin-left: 16px">
              <edit-icon /> 删除
            </t-link>
            <t-link theme="primary" hover="color" @click="copy(item.id)" style="margin-left: 16px">
              <edit-icon /> 复制
            </t-link>
          </t-list-item>
        </t-list>
        <t-button theme="primary" @click="addNew">
            <add-icon slot="icon" />新建
        </t-button>
      </t-space>
    </div>
    <div>
      <t-drawer :visible="visible" @close="handleClose" :onConfirm="handleConfirm" header="方案新增">
      <div class="t-drawer-demo-div">
        <span>方案名称</span>
        <t-input v-model="name" clearable :maxlength="10" show-limit-number/>
      </div>
      <div class="t-drawer-demo-div">
        <span>描述</span>
        <t-textarea v-model="description" placeholder="请输入内容" />
      </div>
      <div class="t-drawer-demo-div">
        <span>类型</span>
        <t-space>
          <t-radio-group name="billType" v-model="billType" :options="options" allowUncheck ></t-radio-group>
        </t-space>
      </div>
    </t-drawer>
    <Menu /> 
    </div>
  </div>
  </template>
  
  <script>
  import { Space as TSpace, List as TList, ListItem as TListItem, 
    Link as TLink, ListItemMeta as TListItemMeta,Button as TButton ,
    Input as TInput,Drawer as TDrawer,
    Textarea as TTextarea,RadioGroup as TRadioGroup,Radio as TRadio,
    MessagePlugin,
  } from 'tdesign-vue';
  import { AddIcon,EditIcon } from 'tdesign-icons-vue';  
  import Menu from './Menu.vue';
  import requestAPI from '../js/ServerAPI.js'

  export default {
    components: { TSpace, TList, TListItem, TLink, TListItemMeta, 
      EditIcon ,AddIcon,TButton,TInput,TDrawer,TTextarea,TRadioGroup,TRadio,
      Menu
    },
    data() {
      return {
        imageUrl: 'https://i.52112.com/icon/jpg/256/20200702/82284/3457261.jpg',
        //抽屉默认值
        visible:false,
        id:'',
        name:'',
        description:'',
        solutionList:[],
        billType:'',
        options:[],
      };
    },
    async created() {

      const postData = {
        enterprise: localStorage.getItem('enterprise'),
      }

      requestAPI(this.$postUrl+'/solution/list', postData, (response) => {
        this.solutionList = response;
      });

      requestAPI(this.$postUrl+'/solution/getType', postData, (response) => {
        this.options = response.map(item => {
          return {
            label: item.name,
            value: item.type,
            checked:false
          };
        });
      });
    },
    methods: {
       addNew(){
          this.setVisible(true);
        },
        edit(id,name,description){
          this.id = id;
          this.name = name;
          this.description = description;

          // //单选值在列表中没法承载。需要单据进行解析判断
          const currentNode = this.solutionList.find(item => item.id == id);
          this.billType = this.options.find(item => item.value == currentNode.billType).value;

          this.setVisible(true);
        },
        remove(id){
          const postData = {
            id: id,
          }
          requestAPI(this.$postUrl+'/solution/delete', postData, (response) => {
              this.solutionList = this.solutionList.filter(item => item.id !== id);
              MessagePlugin.info('删除成功');
          });
        },
        copy(id){
          const postData = {
            id: id,
          }
          requestAPI(this.$postUrl+'/solution/copy', postData, (response) => {
              MessagePlugin.info('复制成功');
          });     
        },
        setVisible(state) {
          this.visible = state;
        },
        handleClose() {
          this.setVisible(false);
        },
        async handleConfirm(){

          const postData = {
            id: this.id,
            enterprise: this.$route.params.enterprise,
            name:this.name,
            description:this.description,
            billType:this.billType,
          }

          let solid = '';
          let url = '';

          if(this.id == '' || this.id == undefined){
            solid = '';
            url = '/solution/add';
          }else{
            solid = this.id;
            url = '/solution/update';
          }

          requestAPI(this.$postUrl+url, postData, (response) => {

            let solutionid = '';
            if(solid == ''){
              solutionid = response.id;
            }else{
              solutionid = solid;
            }

            this.$router.push(
            {
              name: 'Prompt',
              params: {
                solution: solutionid
              }
            });   

          }); 
        }
    }
  };
  </script>
  
  <style scoped>
  .full-screen-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, #6dd5ed, #2193b0); /* 添加渐变背景 */
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: top;
  }
  .t-button {
    margin-right: 20px;
  }
  .t-drawer-demo-div {
    margin-bottom: 24px;
  }
  </style>